/*--------------------------
      Video
--------------------------*/
.video__btn {
    text-align: center;
    display: inline-block;
    position: relative;
    height: 36px;
    padding-left: 54px;

    .video__player {
        position: absolute;
        top: 0;
        left: 0;
        width: 54px;
        height: 36px;
        line-height: 36px;
        border-radius: 2px;
        color: $color-white;
        background-color: $color-primary;
        @include prefix(transition, all .3s linear, webkit moz ms o);
    }

    &-rounded {
        height: 60px;
        padding-left: 60px;

        .video__player {
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 50%;
            box-shadow: 0 0 0 7px rgba(255, 255, 255, .25);
        }

        &:hover {
            .video__player {
                box-shadow: 0 0 0 10px rgba(255, 255, 255, .25);
            }
        }
    }

    .video__btn-title {
        font-size: 15px;
        font-weight: 700;
        line-height: 1.3;
        display: inline-block;
        margin-left: 22px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

.video__btn-white {
    .video__player {
        color: $color-secondary;
        background-color: $color-white !important;
    }
}

.video-banner {
    position: relative;

    .video__btn {
        position: absolute;
        top: 50%;
        left: 50%;
        @include prefix(transform, translate(-50%, -50%), webkit moz ms o);
    }

    .video__btn-title {
        position: absolute;
        bottom: -30px;
        left: -7px;
        line-height: 1.5;
    }
}

// /* Mobile Phones */
// @include xs-screens {
//     .video__btn {
//         padding-left: 50px;
//         height: 50px;

//         .video__player {
//             width: 50px;
//             height: 50px;
//             line-height: 50px;
//         }
//     }
// }