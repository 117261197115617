/*----------------------------
     Testimonial
------------------------------*/

.testimonials .cta {
    margin-top: -150px;
    position: relative;
    background-color: rgba(228, 231, 233, .5);

    .cta__item {
        background-color: #fff;
        box-shadow: 0 5px 23px rgba(40, 40, 40, .08);
        padding: 45px 50px;

        &:first-of-type {
            margin-right: 5px;
        }

        &:last-of-type {
            margin-left: 5px;
        }
    }

    .cta__title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .cta__icon {
        color: $color-secondary;
        font-size: 70px;
        line-height: 1;
    }

    .cta__desc {
        font-size: 14px;
    }

    .cta__content {
        padding-right: 30px;
        padding-left: 30px;
    }

    .or-seperator {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        transform: translate(-50%, -50%);
        text-align: center;
        border-radius: 50%;
        color: $color-secondary;
        font-weight: 700;
        width: 65px;
        height: 65px;
        line-height: 45px;
        background-color: $color-white;
        border: 10px solid #e4e7e9;
    }
}

.testimonial__desc {
    position: relative;
    z-index: 2;
    font-family: $font-body;
    font-size: 18px;
    line-height: 1.5;
    color: $color-secondary;
    margin-bottom: 30px;
}

.testimonial__meta-title {
    font-family: $font-body;
    font-size: 14px;
    margin-bottom: 8px;
    white-space: nowrap;
}

.testimonial__meta-desc {
    position: relative;
    z-index: 2;
    font-size: 13px;
    line-height: 1;
    margin-bottom: 0;
}

.testimonial__thumb {
    width: 53px !important;
    height: 53px;
    overflow: hidden;
    border-radius: 50%;
    padding: 4px;
    border: 2px solid transparent;
    cursor: pointer;
    @include prefix(transition, all 0.4s ease, webkit moz ms o);

    img {
        max-width: 100%;
        border-radius: 50%;
        margin: auto;
    }

    &.slick-current {
        border-color: $color-primary;
    }
}
.arrow_left_right {

  .slick-arrow.slick-prev {
    top: 100% !important;
    right: -232px !important;
    left: 0 !important;
  }
  .slick-arrow.slick-next {
    top: 100% !important;
    right: -80px !important;
  }
}

.testimonials {
    .slider-nav {
        max-width: 105px;
        margin-top: 12px!important;

        .testimonial__thumb:not(.slick-active) {
            opacity: 0;
        }
    }

    .slick-list {
        margin-right: 0;
        margin-left: 0;
    }

    .slick-slide {
        margin-right: 0;
        margin-left: 0;
    }
}

.testimonial-box {
    position: absolute;

    &:nth-of-type(1) {
        top: 18%;
        left: 5%;
    }

    &:nth-of-type(2) {
        top: 58%;
        left: 23%;
    }

    &:nth-of-type(3) {
        top: 50%;
        left: 46%;
    }

    &:nth-of-type(4) {
        top: 9%;
        left: 65%;
    }

    &:nth-of-type(5) {
        top: 45%;
        left: 67%;
    }

    .testimonial__thumb {
        position: relative;
        bottom: 0;
        left: 0;
        transition: all .3s linear 0ms;
        border: 2px solid #c1c9cd;
        width: 44px !important;
        height: 44px;
        padding: 4px;
        border-radius: 50%;

        img {
            border-radius: 50%;
        }

        &:hover {
            border-color: $color-primary;

            .pulsing-animation {
                border: 1px solid rgba(71, 161, 69, .3);
                animation: pulsing 2s linear 0s infinite running;

                &.pulsing-animation-2 {
                    animation-delay: 1.3s;
                }

                &.pulsing-animation-3 {
                    animation-delay: 2.5s;
                }
            }
        }
    }

    .pulsing-animation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .testimonial__panel {
        position: absolute;
        opacity: 0;
        bottom: 60px;
        left: 50%;
        z-index: 20;
        width: 330px;
        border-radius: 2px;
        visibility: hidden;
        margin-bottom: -20px;
        padding: 25px 23px 25px 30px;
        background-color: $color-white;
        transition: all .3s linear 0ms;
        box-shadow: 0 5px 23px rgba(40, 40, 40, .08);

        .testimonial__desc {
            font-size: 15px;
            font-weight: 700;
            color: #0e2b3d;
            margin-bottom: 0;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            border-bottom: 10px solid transparent;
            border-left: 12px solid $color-white;
        }
    }

    &.testimonial-hover-left {
        .testimonial__panel {
            right: 50%;
            left: auto;

            &:after {
                left: auto;
                right: 0;
                border-left: 0;
                border-right: 12px solid $color-white;
            }
        }
    }

    &:hover {
        .testimonial__panel {
            opacity: 1;
            visibility: visible;
            margin-bottom: 0;
        }
    }
}

/* Mobile Phones and tablets */
@media (max-width:992px) {
    .testimonial__desc {
        font-size: 17px;
    }

    .testimonial__meta-title {
        font-size: 15px;
    }
}

/* Mobile Phones and tablets */
@include xs-screens {
    .testimonial-box {
        .testimonial__panel {
            padding: 15px;
            max-width: 270px;
        }

        &:nth-of-type(4) {
            left: 85%;
        }

        &:nth-of-type(3) {
            .testimonial__panel {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &:nth-of-type(5) {
            left: 77%;
        }
    }

    .testimonials .cta {
        margin-top: 0;
        flex-direction: column;

        .cta__item {
            padding: 15px 20px;
            margin-bottom: 10px;

            &:first-of-type {
                margin-right: 0;
            }

            &:last-of-type {
                margin-left: 0;
            }
        }

        .cta__content {
            padding-right: 10px;
            padding-left: 10px;
        }

        .or-seperator {
            display: none;
        }
    }
}