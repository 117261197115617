/*--------------------------
        pricing
--------------------------*/
.page-title-layout4+.pricing {
    margin-top: -265px;
    z-index: 2;
}

.pricing-item {
    position: relative;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    background-color: $color-white;
    box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.08);
    @include prefix(transition, all .3s ease, webkit moz ms o);

    .pricing__top {
        font-size: 15px;
        font-weight: 700;
        padding: 35px 15px;
        text-align: center;
        margin-bottom: 60px;
        border-bottom: 2px solid #e5e8ea;
    }

    .pricing__header {
        margin-bottom: 30px;
    }

    .pricing__title {
        color: #133a60;
        font-size: 27px;
        margin-bottom: 20px;
    }

    .pricing__currency {
        font-size: 45px;
        font-weight: 400;
    }

    .pricing__price {
        font-family: $font-heading;
        font-size: 60px;
        font-weight: 500;
        color: $color-secondary;
        line-height: 1;
    }

    .pricing__time {
        font-size: 15px;
        font-weight: 700;
    }

    .pricing__desc {
        font-size: 14px;
        margin-bottom: 25px;
    }

    .pricing__list {
        margin-bottom: 34px;

        li {
            margin-bottom: 12px;

            &:before {
                font-size: 8px;
                width: 20px;
                height: 20px;
                line-height: 20px;
            }
        }
    }

    .btn {
        height: 70px;
        line-height: 70px;
        padding: 0 20px;
    }

    .pricing__header,
    .pricing__content,
    .pricing__footer {
        padding-right: 50px;
        padding-left: 50px;
    }

    .pricing__footer {
        padding-bottom: 50px;
    }
}

.pricing {
    .btn.btn__secondary.btn__bordered {
        min-width: 180px;
        height: 60px;
        line-height: 60px;
    }
}

/* Mobile Phones & tablets and Small Screens */
@media (max-width:992px) {
    .page-title-layout4+.pricing {
        margin-top: 0;
    }

    .pricing-item {
        height: auto;

        .pricing__list {
            margin-bottom: 20px;
        }

        .pricing__desc {
            font-size: 12px;
        }

        .pricing__price {
            font-size: 40px;
        }

        .pricing__currency {
            font-size: 25px;
        }

        .pricing__top {
            padding: 15px;
            margin-bottom: 30px;
        }

        .pricing__header,
        .pricing__content,
        .pricing__footer {
            padding-right: 20px;
            padding-left: 20px;
        }

        .pricing__footer {
            padding-bottom: 20px;
        }

    }
}