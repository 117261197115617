/*-----------------------
    Progress Bars
------------------------*/
.progress-item {
    margin-bottom: 27px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e5e8ea;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.progress {
    position: relative;
    width: 100%;
    height: 6px;
    overflow: visible;
    border-radius: 6px;
    background-color: transparent;
}

.progress-bar {
    position: relative;
    border-radius: 6px;
    height: 6px;
    width: 0;
    overflow: visible;
    background-color: $color-primary;
    @include prefix(transition, width 2s ease, webkit moz ms o);
}

.progress__title {
    position: relative;
    z-index: 2;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px;
}

.progress__percentage {
    position: absolute;
    top: -18px;
    right: 0;
    z-index: 2;
    color: $color-body;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
}