.trams__body {
  // background-color: #000;
  min-height: 100vh;
  // color: #fff;
  padding-top: 2rem;
}

.bg-grays {
  background-color: #000 !important;
}


