// Sass Variables

$color-primary: #133a60;
$color-secondary: #03182d;
$color-tertiary: #133a60;
$color-body: #9b9b9b;
$color-dark: #222222;
$color-black: #000000;
$color-gray: #f9f9f9;
$color-white: #ffffff;
$later-space: 10px;

$font-heading: 'Gloock',
serif ;

$font-body: 'Roboto',
sans-serif;

$body-font-size: 15px;