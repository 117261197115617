/*-------------------------
   Typography
--------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-secondary;
    font-family: $font-heading;
    text-transform: capitalize;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 20px;
}

p {
    line-height: 1.7;
    margin-bottom: 15px;

}
.para_colour{
    color: $color-secondary;
    font-family: $font-body;
    text-transform: capitalize;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 20px;
}

h1 {
    font-size: 52px;
}

h2 {
    font-size: 42px;
}

h3 {
    font-size: 38px;
}

h4 {
    font-size: 32px;
}

h5 {
    font-size: 24px;
}

h6 {
    font-size: 18px;
}

.heading__subtitle {
    font-family: $font-body;
    font-size: 15px;
    color: $color-primary;
    margin-bottom: 12px;
}
.heading__subtitle_para {
    text-transform: capitalize;
    font-weight: 500;
    line-height: 1.3;
    font-family: $font-body;
    font-size: 16px;
    color: $color-primary;
    margin-bottom: 32px;
    width: 70%;
    margin: auto;
    text-align: center;
   
}
.heading__title_about {
    font-size: 25px;
    margin-bottom: 20px;

    .underlined-text {
        border-bottom: 4px solid $color-primary;
    }
    
}
.heading__title_section {
    font-size: 25px;
    margin-bottom: 20px;
    font-family: $font-body;
    .underlined-text {
        border-bottom: 4px solid $color-primary;
    }
    
}

.heading__title_feturedsection {
    font-size: 18px;
    margin-bottom: 20px;
    color: #133a60;
    text-transform: initial;

    .underlined-text {
        border-bottom: 4px solid $color-primary;
    }
}
.heading__title {
    font-size: 37px;
    margin-bottom: 20px;
    letter-spacing: 2px;

    .underlined-text {
        border-bottom: 4px solid $color-primary;
    }
}

.heading__desc {
    margin-bottom: 0;
}
.heading__desc_service {
    margin-bottom: 0;
    margin-top: -87px;
    margin-left: 15px;
}
.heading__desc_service {
    margin-bottom: 0;
    text-align: left;
}

.heading-layout2 {
    .heading__title {
        font-size: 40px;
    }
}

.heading-layout3 {
    .heading__title {
        font-size: 50px;
    }
}

.heading-light {

    .heading__subtitle {
        color: $color-gray;

        &:after {
            background-color: $color-white;
        }
    }

    .heading__title,
    .heading__desc {
        color: $color-white;
    }
}

.text__block-title {
    font-size: 25px;
    margin-bottom: 17px;
}

.text__block-desc {
    font-size: 16px;
    line-height: 26px;
}

.text__link {
    font-size: 14px;
    font-weight: 700;

    a {
        color: $color-secondary;

        &:hover {
            color: $color-primary;
        }
    }
}

@media (max-width:992px) {
    .heading__title {
        font-size: 30px;
    }
}

/* Mobile Phones and tablets */
@include xs-sm-screens {
    .heading__title {
        font-size: 21px;
        margin-bottom: 10px;
    }
    .heading__subtitle_para {
        text-transform: capitalize;
       font-weight: 500;
       line-height: 1.3;
        font-family: $font-body;
        font-size: 15px;
        color: $color-primary;
        margin-bottom: 32px;
        width: 100%; 
        text-align: center;

        
    }
    .heading__subtitle,
    .text__link {
        font-size: 13px;
    }
    p{
        width: 100%;
    }

    .heading__desc {
        font-size: 13px;
        line-height: 23px;
    }

    .heading-layout2 .heading__title {
        font-size: 24px;
    }

    .heading-layout3 .heading__title {
        font-size: 26px;
    }

    .text__block-desc {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
    }
}