/*-----------------------
    Services 
--------------------------*/
.service-item {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 30px;
    box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.05);
    min-height: 500px !important;
    @include prefix(transition, all .4s ease, webkit moz ms o);

    &:not(.service-item-custom) {
        background-color: $color-white;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 110px;
            left: 110px;
            opacity: 0;
            height: 3px;
            border-radius: 2px;
            background-color: $color-primary;
            @include prefix(transition, all .4s ease, webkit moz ms o);
        }

        .btn {
            height: 40px;
            line-height: 38px;
            min-width: 138px;
            padding: 0 20px;

            span {
                display: inline-block;
            }

            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                width: 20px;
                height: 20px;
                line-height: 20px;
                border-radius: 50%;
                text-align: center;
                display: inline-block;
                opacity: 0;
                color: $color-primary !important;
                background-color: $color-white !important;
                @include prefix(transition, all 0.3s linear, webkit moz ms o);
            }
        }
    }

    .service__content {
        padding: 50px;
    }

    .service__icon {
        color: $color-secondary;
        font-size: 70px;
        line-height: 1;
        margin-bottom: 20px;
        @include prefix(transition, all .3s linear, webkit moz ms o);
    }

    .service__title {
        font-size: 24px;
        margin-bottom: 15px;
        letter-spacing: 1px;
    }

    .service__desc {
        font-size: 14px;
        margin-bottom: 22px;
    }

    .service__img {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 70%;
            background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, .99) 5%, rgba(255, 255, 255, 0));
            background-image: -moz-linear-gradient(top, rgba(255, 255, 255, .99) 5%, rgba(255, 255, 255, 0));
            background-image: -ms-linear-gradient(top, rgba(255, 255, 255, .99) 5%, rgba(255, 255, 255, 0));
            background-image: -o-linear-gradient(top, rgba(255, 255, 255, .99) 5%, rgba(255, 255, 255, 0));
            background-image: linear-gradient(top, rgba(255, 255, 255, .99) 5%, rgba(255, 255, 255, 0));
        }
    }

    &:hover {
        box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, .12);

        &:before {
            opacity: 1;
            left: 30px;
            right: 30px;
        }

        .service__icon {
            color: $color-primary;
        }

        &:not(.service-item-custom) {
            .btn {
                padding-left: 15px;
                padding-right: 40px;

                &:before {
                    @include prefix(transform, scaleX(1), webkit moz ms o);
                    @include prefix(transform-origin, left center, webkit moz ms o);
                }

                i {
                    opacity: 1;
                    right: 15px;
                }
            }
        }
    }
}

.service-item-custom {
    padding: 55px 55px 60px 60px;
    margin-top: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 30px);

    .service__title {
        color: $color-white;
        font-size: 34px;
        margin-bottom: 35px;
    }

    .service__desc {
        color: $color-white;
        margin-bottom: 0;
    }
}

.services-layout3 {
    .service-item {
        text-align: center;
    }
}

.services-carousel {
    overflow-x: hidden;

    .slick-list {
        margin: -15px;
        overflow: visible;
    }

    .slick-slide {
        margin: 15px;
        -webkit-transition: opacity .3s ease, visibility .3s ease, box-shadow 0.4s ease;
        -moz-transition: opacity .3s ease, visibility .3s ease, box-shadow 0.4s ease;
        -ms-transition: opacity .3s ease, visibility .3s ease, box-shadow 0.4s ease;
        -o-transition: opacity .3s ease, visibility .3s ease, box-shadow 0.4s ease;
        transition: opacity .3s ease, visibility .3s ease, box-shadow 0.4s ease;

        &.slick-active {
            opacity: 1;
            visibility: visible;
        }

        &:not(.slick-active) {
            opacity: 0;
            visibility: hidden;
        }
    }

    .slick-arrow {
        display: none!important;
        top: auto;
        bottom: -50px;
        border: 0;
        color: $color-secondary;
        transform: translateY(0);
        

        &.slick-next {
            &:before {
                content: "\e905";
                font-size: 40px;
            }
        }

        &.slick-prev {
            right: 80px;
            left: auto;

            &:before {
                content: "\e903";
                font-size: 40px;
            }
        }
    }

    .slick-dots {
        position: absolute;
        top: auto;
        right: 34px;
        bottom: -40px;
    }

    .service-item {
        margin-bottom: 50px;
    }
}

.services-carousel-2 {
    .slick-dots {
        position: absolute;
        right: 0;
        bottom: -15px;
    }
}

/* Mobile Phones & tablets and Small Screens */
@media (max-width:992px) {
    .service-item {
        .service__content {
            padding: 20px;
        }

        .service__icon {
            font-size: 50px;
            margin-bottom: 10px;
        }

        .service__title {
            font-size: 18px;
            margin-bottom: 5px;
        }

        .service__desc {
            font-size: 13px;
            margin-bottom: 10px;
        }
    }

    .service-item-custom {
        height: auto;
        padding: 20px;

        .service__title {
            font-size: 24px;
            margin-bottom: 10px;
        }

        .service__desc {
            margin-bottom: 40px;
        }
    }
}


@include xs-screens {
    .services-carousel {
        .slick-dots {
            right: 0;
            bottom: 15px;
            width: 100%;
        }

        .slick-arrow {
            bottom: 0;

            &.slick-prev {
                right: auto;
                left: 0;
            }
        }

        .btn-wrapper {
            text-align: center;
            margin-top: 20px;
        }
    }
}