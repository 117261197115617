/*----------------------
    Pre Loader
-----------------------*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: $color-white;

    .sk-cube-grid {
        width: 40px;
        height: 40px;
        display: block;
        margin: 100px auto;
    }

    .sk-cube-grid .sk-cube {
        width: 33%;
        height: 33%;
        float: left;
        background-color: $color-primary;
        animation: cubeAnimation 1.3s infinite ease-in-out;

        &:nth-of-type(1) {
            animation-delay: 0.2s;
        }

        &:nth-of-type(2) {
            animation-delay: 0.3s;
        }

        &:nth-of-type(3) {
            animation-delay: 0.4s;
        }

        &:nth-of-type(4) {
            animation-delay: 0.1s;
        }

        &:nth-of-type(5) {
            animation-delay: 0.2s;
        }

        &:nth-of-type(6) {
            animation-delay: 0.3s;
        }

        &:nth-of-type(7) {
            animation-delay: 0s;
        }

        &:nth-of-type(8) {
            animation-delay: 0.1s;
        }

        &:nth-of-type(9) {
            animation-delay: 0.2s;
        }
    }
}

@keyframes cubeAnimation {

    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }

    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}