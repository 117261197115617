body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

*{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.video-slider .slick-slide {
  width: 378px !important;
}

.offer-slider .slick-slide {
  width: 385px !important;
}
/* service icon */
.service__icon1 {
  height: 70px;
  width: 70px;
  margin-bottom: 10px;
}

.hb-from {
  max-width: 750px;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .service__icon1 {
    height: 40px;
    width: 40px;
    margin-bottom: 10px;
  }
}
