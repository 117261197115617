/*---------------------------
    Counters
--------------------------*/
.counter-item {
    margin-bottom: 20px;

    .counter {
        font-size: 54px;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 16px;
    }

    .counter__desc {
        color: $color-tertiary;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 0;
    }
}

.counters-light {

    .counter-item .counter,
    .counter-item .counter__desc {
        color: $color-white;
    }
}

@media (min-width:992px) {
    .counter-item .counter__desc {
        padding-right: 65px;
    }
}

/* Mobile Phones & tablets and Small Screens */
@media (max-width:992px) {
    .counter-item {
        .counter {
            font-size: 25px;
        }

        .counter__desc {
            font-size: 11px;
            line-height: 20px;
        }
    }
}

@media (max-width: 375px) {
    .counter-item .counter__desc {
        font-size: 10px;
        line-height: 15px;
    }
}