/*-------------------------
   Background & Colors
--------------------------*/

/* Colors */
.color-white {
    color: $color-white !important;
}

.color-gray {
    color: $color-gray !important;
}

.color-dark {
    color: $color-dark !important;
}

.color-primary {
    color: $color-primary !important;
}

.color-secondary {
    color: $color-secondary !important;
}

.color-heading {
    color: $color-secondary !important;
}

.color-body {
    color: $color-body !important;
}

/* backgrounds */
.bg-white {
    background-color: $color-white !important;
}

.bg-gray {
    background-color: $color-gray !important;
}

.bg-dark {
    background-color: $color-dark !important;
}

.bg-heading {
    background-color: $color-secondary !important;
}

.bg-primary {
    background-color: $color-primary !important;
}

.bg-secondary {
    background-color: $color-secondary !important;
}

.bg-img {
    position: relative;
    z-index: 1;
}
.bg-img_404 {
    position: relative;
    background-size: cover ;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../../../assets/images/banners/2.jpg);
    height: 100vh;
    width: 100%;
    z-index: 1;



}

.background-size-auto {
    background-size: auto !important;
}

.bg-parallax {
    background-attachment: fixed;
}

.bg-overlay:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(#030d13, 0.15);
}

.bg-overlay-2:before {
    background-color: rgba(#030d13, 0.35);
}

.bg-overlay-3:before {
    background-color: rgba(#030d13, 0.05);
}

.bg-overlay-gradient:before {
    background-color: transparent;
    background-image: -webkit-linear-gradient(to bottom, rgba(3, 13, 19, .52), rgba(27, 26, 26, .18));
    background-image: -moz-linear-gradient(to bottom, rgba(3, 13, 19, .52), rgba(27, 26, 26, .18));
    background-image: -ms-linear-gradient(to bottom, rgba(3, 13, 19, .52), rgba(27, 26, 26, .18));
    background-image: -o-linear-gradient(to bottom, rgba(3, 13, 19, .52), rgba(27, 26, 26, .18));
    background-image: linear-gradient(to bottom, rgba(3, 13, 19, .52), rgba(27, 26, 26, .18));
}

.bg-overlay-primary:before {
    background-color: rgba($color-primary, 0.9);
}

.bg-overlay-primary-2:before {
    background-color: rgba($color-primary, 0.8);
}