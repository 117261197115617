/*---------------------------
        Forms
----------------------------*/
label {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 10px;

    &.error {
        color: red;
        font-size: 14px;
        font-weight: 400;
        margin: 7px 0 0 0;
    }
}

.form-group {
    position: relative;
    margin-bottom: 30px;
}

.form-control {
    height: 60px;
    border: 2px solid #e7ebef;
    background-color: $color-white;
    padding: 0 20px;
    border-radius: 2px;
    color: #51668a;

    &:focus {
        background-color: $color-white;
        border-color: $color-primary;
    }

    &::-webkit-input-placeholder {
        color: #51668a;
    }

    &:-moz-placeholder {
        color: #51668a;
    }

    &::-moz-placeholder {
        color: #51668a;
    }

    &:-ms-input-placeholder {
        color: #51668a;
    }
}

textarea.form-control {
    min-height: 105px;
    padding-top: 10px;
}

.form__title {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 20px;
}

/* Input Radio */
.label-radio {
    display: block;
    position: relative;
    padding-left: 26px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    color: $color-body;
}

.label-radio input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.radio-indicator {
    position: absolute;
    top: -1px;
    left: 0;
    height: 17px;
    width: 17px;
    background: transparent;
    border: 2px solid #eaeaea;
    border-radius: 50%;
}

.label-radio input:checked~.radio-indicator {
    background: transparent;
}

.label-radio:hover input:not([disabled]):checked~.radio-indicator,
.label-radio input:checked:focus~.radio-indicator {
    background: transparent;
}

.radio-indicator:after {
    content: '';
    position: absolute;
    display: none;
    left: 3px;
    top: 3px;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: $color-primary;
}

.label-radio input:checked~.radio-indicator:after {
    display: block;
}

.custom-control-label {
    font-weight: 400;

    &:before {
        position: absolute;
        top: -2px;
        left: -1.5rem;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: 1px solid #e7ebef;
        border-radius: 0;
    }

    &:after {
        position: absolute;
        top: 3px;
        left: -19.5px;
        width: 1rem;
        height: 1rem;
        content: "";
        background: no-repeat 50%/50% 50%;
        border-radius: 1px;
    }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
    background-color: $color-primary;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 2px;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    background-color: transparent;
    border-color: transparent;
}

.custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #e7ebef !important;
    background-color: transparent;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

input[type=checkbox],
input[type=radio] {
    box-shadow: none !important;
}

.nice-select {
    width: 100%;
    margin-bottom: 30px;

    .list {
        width: 100%;
    }

    .current {
        line-height: 60px;
    }
}

/* Extra Small Devices */
@include xs-sm-screens {

    .form-group,
    .nice-select {
        margin-bottom: 20px;
    }
}