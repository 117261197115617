/*--------------------
    Tabs
--------------------*/
.nav-tabs {
    border-bottom: none;

    .nav__link {
        display: block;
        position: relative;
        padding: 15px 0;
        margin: 0 30px 0 0;
        text-transform: capitalize;
        font-size: 15px;
        font-weight: 700;
        line-height: 1;
        color: $color-body;

        &:last-of-type {
            margin-right: 0;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            width: 0;
            height: 2px;
            margin: auto;
            background-color: $color-primary;
            @include prefix(transition, all .4s linear, webkit moz ms o);
        }

        &.active,
        &:hover {
            color: $color-secondary;

            &:after {
                width: 100%;
            }
        }
    }
}

.nav-tabs-white .nav__link {
    color: $color-white;

    &.active,
    &:hover {
        color: $color-white;
    }

    &:after {
        background-color: #fff;
    }
}

/* Mobile Phones and tablets */
@include xs-sm-screens {
    .nav-tabs .nav__link {
        font-size: 12px;
        padding: 10px 0;
        margin: 0 15px 0 0;

        &:after {
            bottom: 4px;
        }
    }
}