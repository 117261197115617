/*-------------------------
   Timeline
-------------------------*/
.timeline-bar {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -35px;
    background-color: rgba(#e6e9eb, 0.1);

    .col {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: -9px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 5px solid #173e50;
            background-color: $color-primary;
        }
    }
}

.timeline-item {
    position: relative;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    img {
        border-radius: 3px;
    }

    .timeline__year {
        color: $color-white;
        font-size: 20px;
        font-weight: 600;
    }

    .timeline__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.history-timeline {
    .history-timeline-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 230px);
        z-index: -1;
    }

    .slick-list {
        margin: -15px;
    }

    .slick-slide {
        margin: 15px;
    }

    .slick-arrow {
        line-height: 38px;
        border: 2px solid #173e50;
        background-color: #002b3e;

        &:hover {
            color: $color-secondary;
            border-color: $color-white;
            background-color: $color-white;
        }
    }

    .timeline__desc {
        color: #f4f4f4;
    }
}

.read-note__text {
    .text-underlined {
        border-bottom: 2px solid $color-primary;
    }
}

@media screen and (min-width: 1300px) {
    .history-timeline {
        .slick-arrow {
            &.slick-next {
                right: -70px;
            }

            &.slick-prev {
                left: -70px;
            }
        }

        .cta-banner {
            margin-left: 100px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .history-timeline {
        .timeline-bar {
            top: 0;
            margin-top: 5px;
        }

        .slick-arrow {
            top: 0;
            transform: translateY(0);

            &.slick-next {
                right: 0;
            }

            &.slick-prev {
                left: 0;
            }
        }

        .sub__desc,
        .list-items li {
            color: $color-gray;
        }

        .timeline-item {
            align-items: center !important;

            .timeline__content {
                order: 1;

                .timeline__year {
                    order: 1;
                    margin-bottom: 5px !important;
                }

                .timeline__desc {
                    order: 2;
                    margin-bottom: 15px !important;
                }
            }

            .timeline__img {
                order: 2;
            }
        }
    }
}

@include xs-screens {
    .timeline-item {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .history-timeline .history-timeline-bg {
        height: 100%;
    }
}